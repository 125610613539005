import React from "react";
import {
	Box,
	Grid,
	Button,
	Popover,
	Typography,
	IconButton,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	MenuList,
	MenuItem,
	ListItemIcon,
	ListItemText, ListItem,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Timeline,
	TimelineDot,
	TimelineItem,
	TimelineContent,
	TimelineSeparator,
	TimelineConnector,
} from "@mui/lab";
import {
	Edit as EditIcon,
	HistoryToggleOff as HistoryToggleOffIcon
} from "@mui/icons-material";
import PopupState, {
	bindTrigger,
	bindPopover
} from 'material-ui-popup-state';
import orderStatus from "../../../../../constants/orderStatus";
import moment from "moment";
import {palette} from "../../../../../theme/common";
import {useSelector} from "react-redux";

const ActionStatus = (props) => {
	const {
		info,
		disabled,
		onChange
	} = props;
	const classes = useStyles();
	const statusCode = info?.status || '';
	const statusObject = orderStatus[statusCode];
	const statusList = info?.statuses || [];

	const [isShowHistory, setShowHistory] = React.useState(false);
	const handleChangeShowHistory = () => {
		setShowHistory(!isShowHistory);
	}

	return (
		<Box>

			<div className={classes.title}>Статус заказа</div>

			<Grid container spacing={1}>
				<Grid item>
					<div className={classes.status} style={{ background: statusObject?.color }}>
						{ statusObject?.label }
					</div>
				</Grid>
				<Grid item>
					<ButtonChangeStatus
						disabled={disabled}
						onChange={onChange}
					/>
				</Grid>
				<Grid item>
					<IconButton
						className={classes.buttonHistory}
						onClick={handleChangeShowHistory}
					>
						<HistoryToggleOffIcon/>
					</IconButton>
				</Grid>
			</Grid>

			<VisibleContent visible={Boolean(isShowHistory)}>
				<Dialog
					open
					fullWidth
					maxWidth="sm"
					onClose={handleChangeShowHistory}
				>
					<DialogTitle>
						<Typography variant="h3" textAlign="left">История изменения</Typography>
					</DialogTitle>
					<DialogContent>
						<Timeline className={classes.timeline}>
							{statusList.map((item, index) => (
								<TimelineElement
									{...item}
								/>
							))}
						</Timeline>
					</DialogContent>
					<DialogActions>
						<Box px={2} pb={1}>
							<Grid container justifyContent="flex-end">
								<Grid item>
									<Button
										size="small"
										variant="outlined"
										onClick={handleChangeShowHistory}
									>Закрыть</Button>
								</Grid>
							</Grid>
						</Box>
					</DialogActions>
				</Dialog>
			</VisibleContent>

		</Box>
	)
};
const ButtonChangeStatus = (props) => {
	const {
		disabled,
		onChange
	} = props;
	const classes = useStyles();
	const user = useSelector((t) => t?.global?.user);

	const [listOrderStatus] = React.useState(() => {
		let list = [];
		Object.keys(orderStatus).map((statusKey) => {
			const item = orderStatus[statusKey];
			if (statusKey !== 'ALL' && !item.isSystem && (item?.roles || []).length === 0) {
				list.push({
					value: statusKey,
					label: item?.label,
					color: item?.color
				})
			}
			else if ((item?.roles || []).includes(user?.role)) {
				list.push({
					value: statusKey,
					label: item?.label,
					color: item?.color
				});
			}
		});
		return list
	});

	const handleChangeStatus = (item, popupState) => {
		onChange(item.value);
		popupState.close();
	}

	return (
		<PopupState
			variant="popover"
		>
			{(popupState) => (
				<div>
					<IconButton
						className={classes.buttonEdit}
						disabled={disabled}
						{...bindTrigger(popupState)}
					>
						<EditIcon/>
					</IconButton>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						<MenuList
							variant="menu"
							className={classes.menuList}
						>
							{listOrderStatus.map((item, index) => (
								<ListItem
									dense
									divider
									onClick={handleChangeStatus.bind(this, item, popupState)}
								>
									<ListItemIcon sx={{ minWidth: 24 }}>
										<Box
											sx={{
												width: 10,
												height: 10,
												borderRadius: "100%",
												backgroundColor: item.color
											}}
										/>
									</ListItemIcon>
									<ListItemText>
										<div style={{ color: item.color }}>
											{ item?.label }
										</div>
									</ListItemText>
								</ListItem>
							))}
						</MenuList>
					</Popover>
				</div>
			)}
		</PopupState>
	)
};
const TimelineElement = (status) => {
	const {
		user,
		value,
		updated_at
	} = status;
	const classes = useStyles();
	const statusObject = orderStatus[value];
	const fullName = [user?.last_name, user?.first_name].filter((t) => !!t).join(' ') || "Н/Д";

	return (
		<TimelineItem>
			<TimelineSeparator>
				<TimelineDot />
				<TimelineConnector />
			</TimelineSeparator>
			<TimelineContent>
				<div className={classes.timelineDate}>{moment(updated_at).format('DD.MM.YYYY HH:mm:ss')}</div>
				<div className={classes.timelineFullName}>Пользователь: {fullName}</div>
				<div className={classes.status} style={{ background: statusObject?.color }}>
					{statusObject?.label}
				</div>
			</TimelineContent>
		</TimelineItem>
	)
};
const VisibleContent = ({ visible, children }) => {
	if (!visible) return null
	return children || null
}

const useStyles = makeStyles(() => ({
	title: {
		marginBottom: "10px",
		fontWeight: "600",
		fontSize: "18px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	status: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "6px 24px",
		borderRadius: "8px",
		height: 32,
		boxSizing: "border-box",

		fontWeight: "600",
		fontSize: "15px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#FFFFFF",
	},
	buttonHistory: {
		height: 32,
		width: 32,
		borderRadius: 8,
		padding: 0,
		background: "#E0E6EE!important",

		"& svg": {
			width: 24,
			height: 24,
			color: "#8698B1",
		}
	},
	buttonEdit: {
		height: 32,
		width: 32,
		borderRadius: 8,
		padding: 0,
		background: palette.primary.main + '!important',

		"& svg": {
			width: 24,
			height: 24,
			color: palette.primary.contrastText,
		}
	},

	timeline: {
		alignItems: "flex-start",

		"& .MuiTimelineItem-root": {
			"&:before": {
				content: "none"
			}
		},
		"& .MuiTimelineDot-root": {
			backgroundColor: "#FF3F3F",
			boxShadow: "none"
		},
		"& .MuiTimelineConnector-root": {
			backgroundColor: "#FF3F3F"
		},
	},
	timelineDate: {
		display: "flex",
		alignItems: "center",
		height: 22,
		marginBottom: "8px",

		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#8698B1",
	},
	timelineFullName: {
		marginBottom: "8px",

		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#8698B1",
	},

	menuList: {
		"& .MuiListItem-root": {
			cursor: "pointer",
			"&:hover": {
				background: "#f1f1f1"
			}
		}
	},
}));

export default ActionStatus
