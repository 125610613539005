import React from "react";
import {
	Box,
	Grid,
	Button,
	TextField,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const Filter = (props) => {
	const {
		filter,
		onReset,
		onChange,
		onSubmit
	} = props;
	const classes = useStyles();

	const [isOpen, setOpen] = React.useState(false);
	const handleChangeOpen = () => {
		setOpen(!isOpen)
	};

	const handleChangeFilter = ({ target }) => {
		const { name, value } = target;
		let _filter = {...filter};
		_filter[name] = value;
		onChange(_filter);
	}

	return (
		<Box className={classes.root}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h3" textAlign="left">Поиск по справочнику</Typography>
				</Grid>
				<Grid item>
					<Button
						size="small"
						variant="outlined"
						onClick={handleChangeOpen}
					>{isOpen ? 'Свернуть' : 'Развернуть'}</Button>
				</Grid>
			</Grid>
			<VisibleContent visible={Boolean(isOpen)}>
				<Box mt={2}/>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<TextField
							value={filter.phrase}
							size="small"
							name="phrase"
							variant="outlined"
							label="Интерпретация"
							fullWidth={true}
							onChange={handleChangeFilter}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							value={filter.matching_phrase}
							size="small"
							variant="outlined"
							name="matching_phrase"
							label="Нормативные работы"
							fullWidth={true}
							onChange={handleChangeFilter}
						/>
					</Grid>
				</Grid>
				<Box mt={2}/>
				<Grid container spacing={1}>
					<Grid item>
						<Button
							size="small"
							variant="contained"
							onClick={onSubmit}
						>Применить</Button>
					</Grid>
					<Grid item>
						<Button
							size="small"
							variant="outlined"
							onClick={onReset}
						>Сбросить</Button>
					</Grid>
				</Grid>
			</VisibleContent>
		</Box>
	)
};
const VisibleContent = ({ visible, children }) => {
	if (!visible) return null
	return children
}

const useStyles = makeStyles(() => ({
	root: {
		marginBottom: "20px",
		padding: "20px",
		paddingTop: "16px",
		boxSizing: "border-box",
		background: "white",
		borderRadius: "14px",
		border: "1px solid #F0F0F0",
	}
}));

export default Filter
