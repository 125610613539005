import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,

	Box,
	Grid,
	Button,
	Divider,
	TextField,
	Typography, InputLabel, FormControl, Select, MenuItem,
} from "@mui/material";
import {
	Formik
} from "formik";
import * as Yup from "yup";
import {IMaskInput} from "react-imask";
import userRoles from "../../../../../constants/userRoles";

const initForm = {
	firstName: "",
	lastName: "",
	patronymic: "",
	phone: "",
	role: ""
}

class DialogCreateMechanic extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {},
			onSubmit: null,

			isOpen: false,
			isEdit: false,
		}

		this.refFormik = React.createRef();
	}

	open = ({ onSubmit, editForm }) => {
		this.setState({
			onSubmit: onSubmit,
			form: Boolean(Object.keys(editForm || {}).length > 0) ? editForm : {...initForm},
			isOpen: true,
			isEdit: Boolean(Object.keys(editForm || {}).length > 0)
		})
	}
	close = () => {
		this.setState({
			form: {},
			isOpen: false,
			isEdit: false,
		})
	}

	submit = () => {
		let body = {...this.refFormik.current.values};
		body.phone = body.phone.replace(/\D+/g,"");

		this.state.onSubmit(body, true)
	}
	changeForm = ({ target }) => {
		const { value, name } = target;

		let values = this.refFormik.current?.values || {};
		values[name] = value;
		this.refFormik.current.setValues(values);
	}

	render () {
		const {
			form,
			isOpen,
			isEdit
		} = this.state;

		if (!isOpen) {
			return null
		}

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>
					<Typography variant="h3">
						{isEdit ? "Редактирование пользователя" : "Добавление пользователя"}
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Formik
						innerRef={this.refFormik}
						initialValues={{...form}}
						validationSchema={validationSchema}
						onSubmit={this.submit}
					>{(props) => {
						const {
							values,
							errors,
							touched,
							handleSubmit
						} = props;

						return (
							<Grid container spacing={2} sx={{paddingTop: 1}}>
								<Grid item xs={12}>
									<TextField
										value={values.lastName}
										name="lastName"
										variant="outlined"
										size="small"
										label="Фамилия пользователя"
										placeholder="Введите фамилию пользователя"
										fullWidth
										error={Boolean(touched['lastName'] && errors['lastName'])}
										helperText={touched.lastName && errors.lastName}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										value={values.firstName}
										name="firstName"
										variant="outlined"
										size="small"
										label="Имя пользователя"
										placeholder="Введите Имя пользователя"
										fullWidth
										error={Boolean(touched['firstName'] && errors['firstName'])}
										helperText={touched.firstName && errors.firstName}
										onChange={this.changeForm}
									/>
								</Grid>

								{Boolean(isEdit) && (
									<Grid item xs={12}>
										<TextField
											value={values.patronymic}
											name="patronymic"
											variant="outlined"
											size="small"
											label="Отчество пользователя"
											placeholder="Введите Отчество пользователя"
											fullWidth
											error={Boolean(touched['patronymic'] && errors['patronymic'])}
											helperText={touched.patronymic && errors.patronymic}
											onChange={this.changeForm}
										/>
									</Grid>
								)}

								<Grid item xs={12}>
									<Divider/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										value={values.phone}
										name="phone"
										variant="outlined"
										size="small"
										label="Телефон пользователя"
										placeholder="+7 (000) 000-00-00"
										fullWidth
										error={Boolean(touched['phone'] && errors['phone'])}
										helperText={touched.phone && errors.phone}
										inputProps={{
											mask: "+7 (000) 000-00-00",
										}}
										InputProps={{
											inputComponent: IMaskInput
										}}
										InputLabelProps={{
											shrink: Boolean(values.phone),
										}}
										onChange={this.changeForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth>
										<InputLabel>Роль пользователя</InputLabel>
										<Select
											value={values.role}
											label="Роль пользователя"
											name="role"
											size="small"
											onChange={this.changeForm}
										>
											{Object.keys(userRoles).map((key) => (
												<MenuItem value={key} style={{color: "black"}}>{userRoles[key]}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<Divider/>
								</Grid>

								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={12} lg={6}>
											<Button variant="outlined" fullWidth onClick={this.close}>Закрыть</Button>
										</Grid>
										<Grid item xs={12} lg={6}>
											<Button variant="contained" fullWidth onClick={handleSubmit}>
												{isEdit ? "Сохранить" : "Создать"}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)
					}}</Formik>
				</DialogContent>
			</Dialog>
		)
	}
}

const validationSchema = Yup.object().shape({
	lastName: Yup.string().nullable().required('Заполните поле'),
	firstName: Yup.string().nullable().required('Заполните поле'),
	phone: Yup.string().nullable().length(18, "Некорректно набранный номер").required('Заполните поле'),
});

export default DialogCreateMechanic