import React, {Component} from 'react';
import {Notification, notificationTypes} from "../../../../../common/Notification";
import {Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, TextField, Typography} from "@mui/material";
import {withStyles} from "@mui/styles";
import {
	FileUploader,
	DialogConfirmation
} from "../../../../../components";
import {
	convertNewlinesToBreaks,
	convertBreaksToNewlines
} from "../../../../../helper/convertor";
import {IMaskInput} from "react-imask";

const initForm = {
	value: "",
	admin_note: "",
	cost: '',
	files: [],
};

class DialogCreateRecommendation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {...initForm},

			isOpen: false,
			isEdit: false,
		};

		this.timeOutSaveForm = null;
		this.refFileUploader = React.createRef();
		this.refDialogConfirmation = React.createRef();
	}

	open = ({onSubmit, initForm: editInitForm, onDeleteRecommendationFile}) => {
		const isSaveCrateWork = Boolean(localStorage.getItem('create-recommendation-save-form'));
		const isEdit = Boolean(Object.keys(editInitForm || {}).length > 0);

		this.setState({
			form: editInitForm || initForm,
			onSubmit,

			isOpen: true,
			isEdit: isEdit,
			isSaveCrateWork: isSaveCrateWork,

			onDeleteRecommendationFile
		}, () => {
			if (isSaveCrateWork) {
				this.saveFormInit(false)
			}
		});
	};
	close = () => {
		this.setState({
			form: {...initForm},
			isOpen: false,
			isEdit: false,
			isSaveCrateWork: false
		})
		this.saveFormInitCancel();
	};

	saveFormSet = () => {
		const { form } = this.state;
		let _form = {...form};
		delete _form.files;

		if (!_form.value && !_form.admin_note && !_form.cost) {
			return
		}
		localStorage.setItem('create-recommendation-save-form', JSON.stringify(_form));
	}
	saveFormInit = (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				message: "У вас есть незаконченное работа, желаете ее продолжить?",
				acceptButtonTitle: "Да, продолжить",
				cancelButtonTitle: "Нет, создать новую",
				acceptButtonAction: this.saveFormInit.bind(this, true),
				cancelButtonAction: this.saveFormInitCancel.bind(this)
			})
			return;
		}

		const _form = localStorage.getItem('create-recommendation-save-form');
		if (!_form) {
			return
		}

		const form = {
			...initForm,
			...JSON.parse(_form),
		};
		this.setState({ form })
		this.saveFormInitCancel();
	}
	saveFormInitCancel = () => {
		localStorage.removeItem('create-recommendation-save-form')
	}

	changeForm = ({target}) => {
		clearTimeout(this.timeOutSaveForm);

		const {name, value} = target;

		let form = {...this.state.form}
		form[name] = value;

		this.setState({form})

		this.timeOutSaveForm = setTimeout(() => {
			this.saveFormSet();
		}, 1500)
	};

	create = async () => {
		const error = this.checkValidation()
		if (error) {
			Notification({
				message: error,
				type: notificationTypes.error
			});

			return
		}

		const newForm = {
			...this.state.form,
			value: convertNewlinesToBreaks(this.state.form.value),
		};

		this.state.onSubmit(newForm, true);
	}

	checkValidation = () => {
		const {form} = this.state;

		let errors = [];

		if (!form.value || form.value.length <= 0) {
			errors.push(`Введите описание рекомендации`);
		}

		return errors.join(";<br/>");
	};

	deleteRecommendationFile = async (fileId, callback) => {
		const recommendationId = this.state?.form?.id || 0;

		this.state.onDeleteRecommendationFile(fileId, recommendationId, callback);
	};

	handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.setState({
				form: {
					...this.state.form,
					value: this.state.form.value + ' \n',
				}
			});
		}
	};

	render() {
		const {
			form,

			isOpen,
			isEdit,
			isSaveCrateWork
		} = this.state;
		const {
			classes,
			userRole
		} = this.props;
		const isShowClientNote = Boolean(userRole === 'admin' || userRole === 'consultant');

		if (!isOpen) {
			return null
		}

		return (
			<>
				<Dialog
					fullWidth
					maxWidth={isShowClientNote ? "lg" : "md"}
					scroll="body"
					open={isOpen}
				>
					<DialogTitle>
						<Typography variant="h3">
							{Boolean(isEdit) ? "Редактирование рекомендации" : "Добавление рекомендации"}
						</Typography>
					</DialogTitle>
					<DialogContent>
						<Divider className={classes.divider} sx={{marginTop: "0!important"}}/>
						<Box mb={2}>
							<Typography variant="h4">Основная информация</Typography>
						</Box>
						<Box mb={1}>
							<Grid container spacing={2}>
								<Grid item xs={isShowClientNote ? 6 : 12}>
									<TextField
										value={convertBreaksToNewlines(form.value)}
										name="value"
										label={isShowClientNote ? "Описание рекомендации от механика" : "Описание рекомендации"}
										placeholder="Введите описание рекомендации"
										variant="outlined"
										size="small"
										fullWidth
										multiline
										rows={isShowClientNote ? 8 : 4}
										onChange={this.changeForm}
										onKeyDown={this.handleKeyDown}
									/>
								</Grid>
								{Boolean(isShowClientNote) && (
									<Grid item xs={6}>
										<TextField
											value={convertBreaksToNewlines(form.admin_note || "")}
											name="admin_note"
											label="Описание рекомендации от консультанта"
											placeholder="Введите описание рекомендации"
											variant="outlined"
											size="small"
											fullWidth
											multiline
											rows={8}
											onChange={this.changeForm}
										/>
									</Grid>
								)}
							</Grid>
						</Box>

						<Box>
							<TextField
								value={form.cost}
								label="Общая стоимость работ"
								variant="outlined"
								size="small"
								fullWidth
								inputProps={{
									scale: 2,
									mask: Number,
									thousandsSeparator: "",
									radix: ".",
									mapToRadix: [','],
									onComplete: (value) => this.changeForm({target: {value, name: "cost"}})
								}}
								InputProps={{
									inputComponent: IMaskInput
								}}
								InputLabelProps={{
									shrink: Boolean(form.cost !== ""),
								}}
							/>
						</Box>

						<Divider className={classes.divider}/>

						<Box mb={2}>
							<Typography variant="h4">Фото / видео</Typography>
						</Box>
						<FileUploader
							value={form.files || []}
							multiple={true}
							name="files"
							accept={{
								"image/gif": [".gif"],
								"image/jpeg": [".jpeg", ".jpg"],
								"image/png": [".png"],
								"image/webp": [".webp"],

								"video/mpeg": [".mpeg"],
								"video/mp4": [".mp4"],
								"video/ogg": [".ogv"],
								"video/webm": [".webm"],
								"video/x-msvideo": [".avi"],
								"video/3gpp": [".3gp"],
								"video/3gpp2": [".3g2"],
								"video/quicktime": [".quicktime"],

								'application/pdf': ['.pdf'],
								'application/msword': ['.doc'],
								'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
							}}

							isInitOpenPopUp={Boolean(!isEdit && !isSaveCrateWork)}

							onChange={this.changeForm}
							onDeleteWorkFile={this.deleteRecommendationFile}
						/>

						<Divider className={classes.divider}/>

						<Grid container justifyContent="space-between" spacing={1}>
							<Grid item>
								<Button variant="outlined" size="small" onClick={this.close}>Закрыть</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" size="small" onClick={this.create}>
									{Boolean(isEdit) ? "Изменить" : "Добавить"}
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>

				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>
			</>
		);
	}
}

const styles = {
	divider: {
		margin: "24px -24px",
		"@media(max-width: 1199px)": {
			margin: "12px -8px",
		}
	}
}
DialogCreateRecommendation = withStyles(styles)(DialogCreateRecommendation)

export default DialogCreateRecommendation;
