import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
	RouteWithLayout
} from "../components";
import {
	ClientLayout
} from "../layouts";
import {
	Authorization,

	Applications,
	Application,

	Users,

	Dictionary
} from "../pages";
import {compose} from "recompose";
import {connect} from "react-redux";
import global from "../states/global";


const pages = [
	{
		path: '/authorization',
		component: Authorization,
		layout: ClientLayout,
		rules: [],
		isAuth: false,
		exact: true
	},

	{
		path: '/applications',
		component: Applications,
		layout: ClientLayout,
		rules: [],

		exact: true,
		isAuth: true,
	},
	{
		path: '/applications/:id',
		component: Application,
		layout: ClientLayout,
		rules: [],
		exact: true,
		isAuth: true,
	},

	{
		path: '/users',
		component: Users,
		layout: ClientLayout,
		rules: ['admin'],

		exact: true,
		isAuth: true,
	},

	{
		path: '/dictionary',
		component: Dictionary,
		layout: ClientLayout,
		rules: ['admin'],

		exact: true,
		isAuth: true,
	},
];

const LandingRoutes = (props) => {
	const {
		user
	} = props;
	const isUser = Boolean(Object.keys(user).length > 0);

	return (
		<Switch>
			{pages.map((page, idx) => {
				if ((!isUser && page.isAuth) || (isUser && !page.isAuth)) {
					return null
				}

				return (
					<RouteWithLayout
						key={'page-' + idx}
						{...page}
					/>
				)
			})}

			{Boolean(!isUser) && (
				<Redirect to="/authorization"/>
			)}
			{Boolean(!!isUser) && (
				<Redirect to="/applications"/>
			)}
		</Switch>
	);
};

export default compose(
	connect(
		state => ({
			user: state?.global?.user || {}
		})
	),
)(LandingRoutes);
