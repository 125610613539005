import React from "react";
import {isIos} from "../../common/Platform";

const VideoPreview = ({ onClick, file }) => {
	const _linkFile = () => {
		if (file?.fileUrl) {
			return file?.fileUrl
		}
		return URL.createObjectURL(file.file)
	}

	if (isIos()) {
		let type = file?.file?.type || file?.mimetype;
		if (type === "video/quicktime") {
			type = "video/mp4";
		}

		return (
			<video
				loop={true}
				autoPlay={false}
				onClick={onClick}
				preload="metadata"

				style={{
					maxWidth: "100%",
					maxHeight: "100%"
				}}
			>
				<source
					src={_linkFile() + '#t=0.001'}
					type={type}
				/>
			</video>
		)
	}
	return (
		<video
			src={_linkFile()}
			onClick={onClick}

			style={{
				maxWidth: "100%",
				maxHeight: "100%"
			}}
		/>
	)
}

export default VideoPreview
