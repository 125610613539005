// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Applications from './Applications';

export default compose(
  connect(
    state => ({
      user: state?.global.user,
    }),
    dispatch => ({}),
  ),
)(Applications);
