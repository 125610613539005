import React from "react";
import {
	Box,
	Grid,
	Button,
	Select,
	MenuItem,
	TextField,
	InputLabel,
	Typography,
	FormControl
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	DateRangePicker
} from "@mui/lab";
import {
	VisibleContent
} from "../../../../../components";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";
import {toggleSearchPanel} from "../../../../../states/global";

const Filter = (props) => {
	const {
		initFilter,
		isDiagnostics,

		onReset
	} = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isShownSearchPanel } = useSelector(state => state.global);

	const [filter, setFilter] = React.useState({ ...initFilter });
	React.useEffect(() => {
		setFilter({ ...initFilter })
	}, [initFilter]);


	const handleChangeFilter = ({ target }) => {
		const { name, value } = target;
		let _filter = {...filter};
		_filter[name] = value;
		setFilter(_filter);
	};
	const handleChangeDateRange = ([dateFrom, dateTo]) => {
		let _filter = {...filter};
		_filter.dateTo = dateTo || null;
		_filter.dateFrom = dateFrom || null;
		setFilter(_filter);
	};
	const handleChangeStatus = ({ target }, value) => {
		const name = target?.name;
		let _filter = {...filter};
		_filter.status = Boolean(value) ? name : "";
		setFilter(_filter);
	}
	const handleChangeFilterSelect = ({ target }, value) => {
		const name = target?.name;
		value = value?.props?.value || '';
		handleChangeFilter({
			target: {
				name,
				value
			}
		});
	}

	const handleEnter = (eventElement) => {
		if (eventElement.keyCode === 13) {
			handleSubmitFilter();
		}
	}
	const handleSubmitFilter = () => {
		props.onChange({...filter});
		dispatch(toggleSearchPanel());
	}

	return (
		<Box
			className={clsx({
			[classes.section]: true,
			'--mobile-open': isShownSearchPanel
		})}
			onKeyDown={handleEnter}
		>
			<Typography variant="h3" textAlign="left" mb={2}>Фильтры по заявкам</Typography>

			<Grid container spacing={{ xs: 1, lg: 3 }}>
				<Grid item xs={12} lg={2}>
					<TextField
						value={filter.filterID}
						label="Номер заявки"
						size="small"
						name="filterID"
						fullWidth
						onChange={handleChangeFilter}
					/>
				</Grid>
				<VisibleContent visible={!isDiagnostics}>
					<Grid item xs={12} lg={4}>
						<TextField
							value={filter.search}
							label="Поиск"
							size="small"
							name="search"
							helperText="Поиск осуществляется по полям: Клиент / Исполнитель / VIN"
							fullWidth
							onChange={handleChangeFilter}
						/>
					</Grid>
					<Grid item xs={12} lg={6}>
						<DateRangePicker
							startText="Дата начала"
							endText="Дата окончания"
							value={[filter.dateFrom || null, filter.dateTo || null]}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<Grid container spacing={1} wrap="nowrap" alignItems="center">
										<Grid item sx={{flex: 1}}>
											<TextField {...startProps} size="small" fullWidth/>
										</Grid>
										<Grid item>
											-
										</Grid>
										<Grid item sx={{flex: 1}}>
											<TextField {...endProps} size="small" fullWidth/>
										</Grid>
									</Grid>
								</React.Fragment>
							)}
							onChange={handleChangeDateRange}
						/>
					</Grid>
					<Grid item xs={12} lg={3}>
						<FormControl fullWidth size="small">
							<InputLabel>Статус заказа</InputLabel>
							<Select
								value={filter.status}
								name="status"
								label="Статус заказа"
								onChange={handleChangeFilterSelect}
							>
								<MenuItem value="">Сбросить</MenuItem>
								{[
									{label: "Новая", value: "NEW"},
									{label: "В работе", value: "IN_WORK"},
									{label: "Отдых", value: "REST"},
									{label: "Ожидание запчастей", value: "WAITING_FOR_SPARE_PARTS"},
									{label: "На согласование", value: "APPROVE"},
									{label: "Выполнен", value: "COMPLETED"},
									{label: "Завершен", value: "EXECUTED"},
								].map((_item) => (
									<MenuItem value={_item.value}>
										{ _item.label }
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</VisibleContent>
			</Grid>
			<Box mt={2}/>

			<Grid container spacing={1}>
				<Grid item>
					<Button variant="contained" size="small" onClick={handleSubmitFilter}>
						Применить
					</Button>
				</Grid>
				<Grid item>
					<Button variant="outlined" size="small" onClick={onReset}>
						Сбросить
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
};

const useStyles = makeStyles(() => ({
	section: {
		marginBottom: "20px",
		padding: "20px",
		paddingTop: "16px",
		boxSizing: "border-box",
		background: "white",
		borderRadius: "14px",
		border: "1px solid #F0F0F0",

		"&.--mobile-open": {
			display: "block!important"
		},
		"@media(max-width: 768px)": {
			display: "none",
			position: 'fixed',
			zIndex: 10,
			top: '60px', left: 0, right: 0,
			borderRadius: '0 0 14px 14px',
		}
	}
}));

export default Filter
