import React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";

const MechanicsInfo = ( props ) => {
	const {
		mechanic
	} = props;

	const classes = useStyles();

	return (
		<>
			<Box mb={ 1 }>
				<Typography variant="h3" textAlign="left">Ответственный «пользователь системы»</Typography>
			</Box>
			<Grid className={ classes.root } container spacing={ 1 }>
				<Grid item>
					Фамилия - <b>{ mechanic.lastName }</b>;
				</Grid>
				<Grid item>
					Имя - <b>{ mechanic.firstName }</b>;
				</Grid>
				<Grid item>
					Номер телефона - <b>{ mechanic.phone }</b>;
				</Grid>
			</Grid>
		</>
	);
};

const useStyles = makeStyles(() => ({
	root: {
		"& b": {
			fontWeight: "500",
			textDecoration: "underline"
		}
	}
}));

export default MechanicsInfo;