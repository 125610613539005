const clipboardWriteText = (string) => {
	navigator.clipboard.writeText(string)
		.then(() => {
			return true
		})
		.catch(err => {
		});
}

export {
	clipboardWriteText
}