import React from "react";
import {
	Box,
	Grid,
	Badge,
	Drawer,
	Button,
	Tooltip,
	Backdrop,
	IconButton,
	CircularProgress
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Close as CloseIcon,
	NotificationsNone as NotificationsNoneIcon
} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {getNotifications} from "../../../../states/global";
import orderStatus from "../../../../constants/orderStatus";
import agent from "../../../../agent/agent";
import {useHistory} from "react-router-dom";

const Notification = (props) => {
	const classes = useStyles();
	const notifications = useSelector((state) => state.global.notifications || []);
	const history = useHistory();
	const dispatch = useDispatch();
	const count = notifications.length;

	React.useEffect(() => {
		(async () => {
			await scheduleGetNotifications();
		})();
	}, []);
	const scheduleGetNotifications = async () => {
		await dispatch(getNotifications());
		setTimeout(async () => {
			await scheduleGetNotifications()
		}, (10 * 10000));
	};

	const [isBackdrop, setBackdrop] = React.useState(false);
	const [isOpenDrawer, setOpenDrawer] = React.useState(false);
	const handleChangeOpenDrawer = (val) => {
		setOpenDrawer(val);
	}

	const handleClearAll = async () => {
		setBackdrop(true);
		const isSuccess = await agent.get(`/user/read-all `).then(() => {
			return true
		}).catch(() => {
			return false
		});
		await scheduleGetNotifications();
		setBackdrop(false);
	};
	const handleClearNotification = async (notification) => {
		setBackdrop(true);
		const isSuccess = await agent.get(`/user/read/${ notification.id }`).then(() => {
			return true
		}).catch(() => {
			return false
		});
		await scheduleGetNotifications();
		setBackdrop(false);
	};

	const handleOpenNotification = async (notification) => {
		await handleClearNotification(notification);
		const orderId = notification.orderId;
		history.push(`/applications/${ orderId }`)
	}

	const _tooltipTitle = () => {
		if (count <= 0) {
			return "У вас нет уведомлений"
		}
		return `У вас ${ count } новых уведомлений`
	}

	return (
		<>

			<Tooltip arrow title={_tooltipTitle()}>
				<Badge
					badgeContent={count}
					color="primary"
					className={classes.buttonIcon}
					onClick={handleChangeOpenDrawer.bind(this, true)}
				>
					<NotificationsNoneIcon sx={{ color: "#FF3F3F", fontSize: 20 }}/>
				</Badge>
			</Tooltip>

			<Drawer
				open={isOpenDrawer && Boolean(count > 0)}
				classes={{ root: classes.drawer }}
				anchor="right"
				onClose={handleChangeOpenDrawer.bind(this, false)}
			>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Button
							size="small"
							variant="contained"
							fullWidth
							onClick={handleClearAll}
						>
							Очистить всё
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							size="small"
							variant="outlined"
							fullWidth
							onClick={handleChangeOpenDrawer.bind(this, false)}
						>
							Закрыть
						</Button>
					</Grid>
				</Grid>
				<Box mt={2}/>
				<Grid container spacing={1}>
					{notifications.map((notification) => (
						<Grid item xs={12}>
							<NotificationCard
								item={notification}
								onOpen={handleOpenNotification.bind(this, notification)}
								onRemove={handleClearNotification.bind(this, notification)}
							/>
						</Grid>
					))}
				</Grid>
			</Drawer>

			<Backdrop open={isBackdrop}>
				<CircularProgress/>
			</Backdrop>

		</>
	)
};
const NotificationCard = (props) => {
	const {
		item,
		onOpen,
		onRemove
	} = props;
	const classes = useStyles();
	const status = orderStatus[item?.orderStatus] || {}
	return (
		<Box className={classes.notificationCard}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<div className={classes.notificationCardTitle}>Номер заявки</div>
					<div className={classes.notificationCardValue}>{ item?.tricepsId || "Н/Д" }</div>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.notificationCardTitle}>Клиент</div>
					<div className={classes.notificationCardValue}>
						{ item?.clientFirstName || "Н/Д" }
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.notificationCardTitle}>Механик</div>
					<div className={classes.notificationCardValue}>
						{ item?.mechanicFullName || "Н/Д" }
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className={classes.notificationCardTitle}>Статус заявки</div>
					<div
						className={classes.notificationCardStatus}
						style={{ background: status.color }}
					>
						{ status.label || "Н/Д" }
					</div>
				</Grid>
			</Grid>
			<Box mt={1}/>
			<Grid container spacing={0.5}>
				<Grid item xs={12}>
					<Button
						variant="outlined"
						size="small"
						fullWidth
						className={classes.notificationCardButton}
						onClick={onRemove}
					>Скрыть уведомление</Button>
				</Grid>
				<Grid item xs={12}>
					<Button
						variant="contained"
						size="small"
						fullWidth
						className={classes.notificationCardButton}
						onClick={onOpen}
					>Открыть заявку</Button>
				</Grid>
			</Grid>

			<Tooltip title="Скрыть уведомление" arrow>
				<IconButton className={classes.notificationCardRemove} onClick={onRemove}>
					<CloseIcon sx={{ color: "#152435", fontSize: 20 }}/>
				</IconButton>
			</Tooltip>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	drawer: {
		"& .MuiPaper-root": {
			width: "100%",
			maxWidth: "320px",
			padding: "12px",
			boxSizing: "border-box",
			background: "transparent",
			boxShadow: "none"
		}
	},

	buttonIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 32, height: 32,
		borderRadius: "100%",
		border: "1px solid #FF3F3F",
		marginRight: 8,
		cursor: "pointer"
	},

	notificationCard: {
		padding: "8px",
		boxSizing: "border-box",
		borderRadius: "8px",
		background: "white",
		position: "relative",

	},
	notificationCardTitle: {
		marginBottom: 2,
		fontWeight: "600",
		fontSize: "12px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
		opacity: "0.6",
	},
	notificationCardValue: {
		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "130%",
		letterSpacing: "-0.02em",
		color: "#000000",
	},
	notificationCardStatus: {
		padding: "4px 8px",
		boxSizing: "border-box",
		borderRadius: "4px",
		width: "fit-content",

		fontSize: 14,
		lineHeight: 1,
		fontWeight: '600',
		color: "white"
	},
	notificationCardButton: {
		padding: "6px",
		boxSizing: "border-box",
		height: "auto",
		borderRadius: "4px",

		fontWeight: "600",
		fontSize: "15px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
	},
	notificationCardRemove: {
		position: "absolute",
		top: 4, right: 4,
		width: 32, height: 32
	},
}));

export default Notification
