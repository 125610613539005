import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogContent,
    DialogTitle,

    Divider,

    Grid,

    TextField,

    Typography
} from "@mui/material";
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import {
    Notification,
    notificationTypes
} from "../../../../../common/Notification";
import {
    withStyles
} from "@mui/styles";
import {
    JsBarCodeScanner
} from "../../../../../components";
import { QrReader } from "react-qr-reader";


const initForm = {
    code: '',
    info: "",
};

class DialogBarcodeReader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            form: {...initForm},

            isOpen: false,
            stopStream: true,
        };
    }

    open = ({onSubmit}) => {
        this.setState({
            onSubmit,
            isOpen: true
        })
    }
    close = () => {
        this.setState({
            form: {...initForm},
            isOpen: false,
            stopStream: true,
        })
    }

    changeForm = ({target}) => {
        const {name, value} = target;

        let form = {...this.state.form}
        form[name] = value;

        this.setState({form})
    }
    create = async () => {
        const error = this.checkValidation()
        if (error) {
            Notification({
                message: error,
                type: notificationTypes.error
            })

            return
        }

        this.state.onSubmit(this.state.form);
    }

    checkValidation = () => {
        const {form} = this.state;

        let errors = [];

        if (!form.code) {
            errors.push(`Отсканируйте штрих-код или введите вручную`);
        }

        return errors.join(";<br/>");
    }

    onUpdate = async (err, result) => {
        let form = {...this.state.form}
        if (result) {
            form.code = result.text;

            this.setState({
                form,
                stopStream: true,
            });

            if (await this.props.onCheckOrderByBarcode(result.text)) {
                return null;
            }
        } else {
            form.code = '';
            this.setState({
                form,
                stopStream: false,
            });
        }
    };
    onError = (test) => {
        this.setState({
            stopStream: true,
        });
        alert(test)
    }
    onSuccessScan = async (code) => {
        let form = {...this.state.form}
        form.code = code;

        this.setState({
            form,
            stopStream: true,
        });

        if (await this.props.onCheckOrderByBarcode(code, true, true)) {
            return null;
        }
    }

    setStopStream = (stopStream) => {
        this.setState({stopStream})
    };

    handleCloseStopStream = () => {
        this.setStopStream(true);
    };

    render() {
        const {
            form,

            isOpen,
            stopStream
        } = this.state;
        const {
            classes
        } = this.props;
        return (
            <Dialog
                fullWidth
                maxWidth="md"
                scroll="body"
                open={isOpen}
                onClose={this.close}
            >
                <DialogTitle>
                    <Typography variant="h3">
                        Создание услуги (штрих-код)
                    </Typography>
                    <Typography variant="h6" textAlign="center">
                        Отсканируйте штрих-код или введите вручную
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Divider className={classes.divider} sx={{marginTop: "0!important"}}/>

                    {stopStream && (
                        <Button
                            fullWidth
                            size="large"
                            variant='contained'

                            onClick={this.setStopStream.bind(this, false)}
                        >
                            Сканировать
                        </Button>
                    )}

                    {!stopStream && (
                        <>
                            <JsBarCodeScanner
                              onSuccess={this.onSuccessScan}
                              onResult={this.onSuccessScan}
                              onCancel={this.setStopStream.bind(this, true)}
                            />
                            <Box mt={2}></Box>
                            <Button
                                fullWidth
                                size="large"
                                variant='outlined'

                                onClick={this.handleCloseStopStream}
                            >
                                Отменить сканирование
                            </Button>
                        </>
                    )}

                    <Divider className={classes.divider}/>

                    <TextField
                        value={form.code}
                        name="code"
                        label="Код"
                        placeholder="Введите код"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={!stopStream}
                        onChange={this.changeForm}
                    />

                    <Divider className={classes.divider}/>

                    <Grid container justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Button variant="outlined" size="small" onClick={this.close}>Закрыть</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" size="small" onClick={this.create}>Создать</Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        );
    }
}

const styles = {
    divider: {
        margin: "24px -24px",
        "@media(max-width: 1199px)": {
            margin: "12px -8px",
        }
    }
};

export default withStyles(styles)(DialogBarcodeReader);
