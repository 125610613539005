import React from "react";
import {
	Button,
	Skeleton,
	TextField,
	Pagination,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@mui/material";
import {

} from "@mui/styles";
import {
	AutocompleteServiceTriceps
} from "../../../../applications/Application/components/DialogWorkManager";

const TableCustom = (props) => {
	const {
		form,
		page,
		items,
		total,
		isLoading,

		onChangePage,
		onChangeForm,
		onCreateItem,

		onRemoveItem
	} = props;

	return (
		<>

			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Интерпретация</TableCell>
						<TableCell>Нормативные работы</TableCell>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					<VisibleContent visible={!isLoading}>
						<RowForm form={form} onChange={onChangeForm} onCreate={onCreateItem}/>
						{items.map((item, index) => (<RowItem
							key={`item-${ item.id }`}
							item={item}
							onRemove={onRemoveItem.bind(null, item, false)}
						/>))}
					</VisibleContent>
					<VisibleContent visible={isLoading}>
						<LoadingRows/>
					</VisibleContent>
				</TableBody>
			</Table>

			<Pagination
				page={page}
				count={total}
				onChange={(e, p) => onChangePage(p)}
			/>

		</>
	)
};
const RowItem = (props) => {
	const {
		item,
		onRemove
	} = props;
	return (
		<TableRow>
			<TableCell>{ item?.phrase }</TableCell>
			<TableCell>{ item?.matching_phrase }</TableCell>
			<TableCell align="right">
				<Button
					size="small"
					variant="contained"
					sx={{ whiteSpace: "nowrap" }}
					onClick={onRemove}
				>Удалить связь</Button>
			</TableCell>
		</TableRow>
	)
};
const LoadingRows = () => {
	return new Array(10).fill(1).map(() => (
		<TableRow>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
		</TableRow>
	))
};
const RowForm = (props) => {
	const {
		form,
		onChange,
		onCreate
	} = props;

	const handleChangeService = (event, value) => {
		onChange({
			target: {
				name: "matchingPhrase",
				value
			}
		});
	}

	return (
		<TableRow>
			<TableCell>
				<TextField
					value={form.searchPhrase}
					name="searchPhrase"
					size="small"
					label="Поисковое слово"
					placeholder="Поисковое слово"
					fullWidth
					sx={{background: "white"}}
					onChange={onChange}
				/>
			</TableCell>
			<TableCell>
				<AutocompleteServiceTriceps
					value={form.matchingPhrase}
					name="matchingPhrase"
					size="small"
					label="Результат для поиска"
					placeholder="Результат для поиска"
					fullWidth
					sx={{background: "white"}}
					onChange={handleChangeService}
				/>
			</TableCell>
			<TableCell>
				<Button
					fullWidth
					size="small"
					variant="contained"
					disabled={Boolean(!form.searchPhrase || !form.matchingPhrase)}
					sx={{ height: 42, whiteSpace: "nowrap" }}
					onClick={onCreate}
				>Добавить связь</Button>
			</TableCell>
		</TableRow>
	)
};
const VisibleContent = ({ visible, children }) => {
	if (!visible) return null
	return children
}

export default TableCustom
