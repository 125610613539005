import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Box,
	Grid,
	Switch,
	Tooltip,
	Pagination,
	IconButton,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Delete as DeleteIcon
} from "@mui/icons-material";
import userRoles from "../../../../../constants/userRoles";

const TableCustom = (props) => {
	const {
		data,
		filter,
		isLoad,
		totalPage,

		onChange,
		onChangePage,
		onEditMechanic,
		onRemoveMechanic
	} = props;
	const classes = useStyles();

	const handleOpenEdit = (mechanic) => {
		onEditMechanic(mechanic, false)
	}
	const handleChangePage = (event, newPage) => {
		onChangePage(newPage);
	}
	const handleChaneActiveMechanic = (mechanic, event) => {
		event.stopPropagation();

		let form = {...mechanic}
		delete form.createdAt;
		delete form.updatedAt;
		form.active = !form.active;
		onEditMechanic(form, true);
	}

	const handleRemoveUser = (user, event) => {
		event.stopPropagation();
		onRemoveMechanic(user, false)
	}

	return (
		<>

			{Boolean(isLoad) && (
				<Box className={classes.loading}>
					Идет загрузка...
				</Box>
			)}

			{Boolean(!isLoad) && (
				<>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>ФИО</TableCell>
								<TableCell>Роль</TableCell>
								<TableCell>Номер телефона</TableCell>
								<TableCell align="right">Активен</TableCell>
								<TableCell align="right" width="32px"/>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((mechanic) => {
								const fullName = [mechanic.lastName, mechanic.firstName, mechanic.patronymic].filter((t) => !!t).join(" ");

								return (
									<TableRow
										className={classes.tableRow}
										hover
										onClick={handleOpenEdit.bind(this, mechanic)}
									>
										<TableCell>
											<span className="visible-mobile">ФИО</span>
											<span className="value">{fullName}</span>
										</TableCell>
										<TableCell>
											<span className="visible-mobile">Роль</span>
											<span className="value">{userRoles[mechanic.role]}</span>
										</TableCell>
										<TableCell>
											<span className="visible-mobile">Номер телефона</span>
											<span className="value">{mechanic.phone}</span>
										</TableCell>
										<TableCell align="right">
											<span className="visible-mobile">Активен</span>
											<Switch
												value={mechanic.active}
												checked={mechanic.active}
												onClick={handleChaneActiveMechanic.bind(this, mechanic)}
											/>
										</TableCell>
										<TableCell>
											<Tooltip title="Удалить пользователя" arrow>
												<IconButton color="primary" onClick={handleRemoveUser.bind(this, mechanic)}>
													<DeleteIcon/>
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
					<Grid container mt={8}>
						<Grid item>
							<Pagination
								page={Number.parseFloat(String(filter.page || 1))}
								count={Number.parseFloat(totalPage)}
								onChange={handleChangePage}
							/>
						</Grid>
					</Grid>
				</>
			)}

		</>
	)
}

const useStyles = makeStyles(() => ({
	loading: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 50,
		borderRadius: 8,
		backgroundColor: "white",

		fontSize: 18,
		lineHeight: "24px",
		textAlign: "center",
		color: "black",
		fontWeight: "600"
	},

	table: {
		"@media(max-width: 1199px)": {
			"& .MuiTableHead-root": {
				display: "none"
			},
			"& .MuiTableBody-root": {
				display: "flex",
				flexDirection: "column",

				"& > *": {
					marginTop: 12,
					"&:first-child": {
						marginTop: 0,
					}
				}
			},
		}
	},

	tableRow: {
		"@media(max-width: 1199px)": {
			padding: 12,
			borderRadius: 8,
			background: "white",
			display: "flex",
			flexDirection: "column",

			"& td": {
				border: "none!important",
				padding: "0!important",
				marginTop: 8,
				"&:first-child": {
					marginTop: 0
				}
			},
			"& .visible-mobile": {
				fontSize: 12,
				lineHeight: "14px",
				marginBottom: 4,
				color: "black",
				opacity: "0.8",
				fontWeight: "600"
			},
			"& .value": {
				fontSize: 16,
				lineHeight: "20px",
				color: "black",
				fontWeight: "500"
			},
			"& .MuiTableCell-alignRight": {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",

				"& span": {
					marginBottom: 0
				}
			}
		}
	},
	buttons: {
		"@media(max-width: 1199px)": {
			justifyContent: "initial",
			"& > *": {
				width: "100%"
			},
			"& .MuiButtonBase-root": {
				display: "flex",
				alignItems: "center",

				width: "100%",
				height: 32,
				backgroundColor: "#ff3f3f",
				borderRadius: 4,

				fontSize: 14,
				lineHeight: "16px",
				color: "white",

				"& .MuiSvgIcon-root": {
					fontSize: 16,
				},
				"& .visible-mobile": {
					marginBottom: 0,
					fontSize: 14,
					color: "white",
					marginLeft: 6
				},
			}
		}
	},
}));

export default React.memo(TableCustom)
