import Compressor from "compressorjs";

const optimizationImageVideo = async (files = []) => {
	let filesOptimization = [];

	await Promise.all(files.map(async (file) => {
		if ((file?.file?.type || file?.mimetype || "").includes("video")) {
			filesOptimization.push(file);
		}
		if ((file?.file?.type || file?.mimetype || "").includes("pdf")) {
			filesOptimization.push(file);
		}
		if ((file?.file?.type || file?.mimetype || "").includes("image")) {
			filesOptimization.push({
				...file
			});
		}
	}));

	return filesOptimization
}
async function compress(file) {
	return await new Promise((resolve, reject) => {
		new Compressor(file, {
			quality: 0.6,
			success: resolve,
			error: reject,
		});
	});
}

export default optimizationImageVideo
export {
	compress as compressImage
}
