import React from "react";
import {Box, Button, Grid, IconButton, Tooltip, Typography,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {convertorNumber} from "../../../../../helper/convertor";
import {VideoPreview} from "../../../../../components";
import {useSelector} from "react-redux";

const WorkList = (props) => {
	const {
		data,
		disabledEdit,
		hideButtonCreate,

		onOpenCreateService,
		onOpenEdit,
		onOpenDialogImagesVideos,
		onOpenDialogImagesMessage,

		onDeleteWork,
		isFilesLoading
	} = props;
	const classes = useStyles();

	if (data.length < 1) {
		return (
			<Grid className={classes.root} container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h3" textAlign="left">Перечень работ</Typography>
				</Grid>
				{!Boolean(hideButtonCreate) && (
					<Grid item xs={12}>
						<Button
							variant="contained"
							fullWidth
							disabled={disabledEdit}
							onClick={onOpenCreateService.bind(this, null)}
						>
							Добавить работу
						</Button>
					</Grid>
				)}
				{Boolean(hideButtonCreate) && (
					<Grid item xs={12}>
						<Typography mb={1} className={classes.helpMessage}>Нет работ</Typography>
					</Grid>
				)}
			</Grid>
		);
	}

	return (
		<Grid className={classes.root} container spacing={2}>
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={6}>
						<Typography variant="h3" textAlign="left">Перечень работ</Typography>
					</Grid>
					{!Boolean(hideButtonCreate) && (
						<Grid item xs={6} sx={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
							<Button
								variant="contained"
								disabled={disabledEdit}
								onClick={onOpenCreateService.bind(this, null)}
							>
								Добавить работу
							</Button>
						</Grid>
					)}
				</Grid>
			</Grid>

			{data.map((order) => (
				<Grid key={`order-${order.id}`} item xs={12} lg={6}>
					<OrderCard
						order={order}
						disabledEdit={order.fromTriceps || disabledEdit}

						onOpenEdit={onOpenEdit}
						onOpenDialogImagesVideos={onOpenDialogImagesVideos}
						onOpenDialogImagesMessage={onOpenDialogImagesMessage}
						onDeleteWork={onDeleteWork}
						isFilesLoading={isFilesLoading}
					/>
				</Grid>
			))}
		</Grid>
	)
}
const OrderCard = (props) => {
	const {
		order,
		disabledEdit,

		onOpenEdit,
		onOpenDialogImagesVideos,
		onOpenDialogImagesMessage,

		onDeleteWork,
		isFilesLoading
	} = props;
	const classes = useStyles();
	const {user} = useSelector(state => state.global);
	const getMechanicName = () => {
		if (order.mechanic) {
			return [order.mechanic.firstName, order.mechanic.lastName].join(' ');
		}
	};

	const handleOpenEdit = () => {
		onOpenEdit(order, false)
	}

	const handleDeleteWork = () => {
		onDeleteWork(order.id, false);
	};

	const handleOpenDialogImagesMessage = (index) => {

		const workItems = [...(order?.items || [])];
		const pictures = [...(order?.pictures || [])]
			.map((picture) => {
				const fileId = picture?.fileId;
				const workDescription = workItems.find((t) => [...(t.images || [])].find((i) => i.id === fileId))?.description || "";
				return {
					...picture,
					description: workDescription || ""
				}
			});

		onOpenDialogImagesMessage({
			index,
			files: pictures,
			message: order?.description || ""
		})
	}


	const _mechanic = () => {
		return [
			order?.mechanic?.lastName,
			order?.mechanic?.firstName,
			order?.mechanic?.patronymic,
		].filter((t) => !!t).join(' ') || "Н/Д"
	};
	return (
		<Box className={classes.orderCard}>
			<Box className={classes.orderCardUser}>
				Работа от механика: <span>{_mechanic()}</span>
			</Box>
			<div className="--divider"/>
			<Box className={classes.orderCardHead}>
				<div className={classes.orderCardName} dangerouslySetInnerHTML={{
					__html: order.description
				}}>
				</div>
			</Box>
			<Box className={classes.orderCardBody}>
				{(order.estimate && order.estimate.length > 0) && <>
					<div className={classes.orderCardEstimateTitle}>Перечень запчастей</div>
					<ol className={classes.orderCardEstimate}>
						{(order?.estimate).map((sparePart, index) => (
							<li key={`estimate-${sparePart.description}-${sparePart.partsCost}-${index}`}>
								{sparePart.description} ({convertorNumber(sparePart.partsCost)}₽)
							</li>
						))}
					</ol>
				</>}
			</Box>

			{Boolean((order.pictures || []).length > 0) && (
				<>
					<div className="--divider"/>
					<div className={classes.filesTitle}>Фото / видео</div>
					{
						isFilesLoading ? (
							<div>
								<span className='loader'></span>
								<div className={classes.warning}>Идет загрузка медиафайлов</div>
							</div>
						) : (
							<Grid className={classes.files} container spacing={1}>
								{(order.pictures || []).map((file, index) => {
									const isVideo = Boolean((file?.file?.type || file?.mimetype || "").includes("video"));
									if (!!isVideo) {
										return (
											<Grid item key={`video-file-${file.fileId}`}>
												<VideoPreview
													file={file}
													onClick={handleOpenDialogImagesMessage.bind(this, index)}
													_onClick={onOpenDialogImagesVideos.bind(this, order.pictures || [], index)}
												/>
											</Grid>
										)
									}
									return (
										<Grid item key={`photo-file-${file.fileId}`}>
											<img
												src={file?.fileUrl}
												onClick={handleOpenDialogImagesMessage.bind(this, index)}
												_onClick={onOpenDialogImagesVideos.bind(this, order.pictures || [], index)}
											/>
										</Grid>
									)
								})}
							</Grid>
						)
					}

				</>
			)}

			{(!disabledEdit && (user.role === 'admin' || user.role === 'consultant' || user.id === order.mechanicId)) && (
				<>
					<div className="--divider" style={{marginTop: "auto"}}/>
					<Grid container spacing={1} className={classes.orderCardButton}>
						<Grid item xs={12}>
							<Button variant="outlined" fullWidth onClick={handleOpenEdit}>
								Редактировать
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button variant="outlined" fullWidth onClick={handleDeleteWork}>
								Удалить
							</Button>
						</Grid>
					</Grid>
				</>
			)}
		</Box>
	);
}

const useStyles = makeStyles(() => ({
	root: {
		"@media(max-width: 1199px)": {
			position: "relative",
			paddingBottom: 100
		}
	},
	prices: {

		"@media(max-width: 1199px)": {
			position: "sticky",
			bottom: 0,
			left: 0,
			backgroundColor: "white",
			borderRadius: "8px 8px 0 0",
			padding: 12,
			boxShadow: "0px 2px 8px rgb(142 150 168 / 24%)",
			marginLeft: 16,
			marginTop: 8,

			"& .MuiTypography-root": {
				display: "flex",
				flexDirection: "row",
				flewWrap: "nowrap",

				fontSize: 14,
				lineHeight: "14px",
				whiteSpace: "nowrap"
			},
			"& .MuiGrid-root > *": {
				width: "100%",

				"& span": {
					display: "block",
					flex: 1,
					borderBottom: "1px dotted #647083",
					margin: "0 4px"
				}
			}
		}
	},
	helpMessage: {
		fontWeight: "600",
		fontSize: "18px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},

	orderCard: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		background: "#F4F6F8",
		borderRadius: "16px",
		padding: "20px",
		boxSizing: "border-box",

		"& .--divider": {
			height: 1,
			margin: "12px -20px",
			background: "#152435",
			opacity: 0.1
		},
		"@media(max-width: 1199px)": {
			background: "white"
		}
	},
	orderCardUser: {
		fontSize: "15px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		color: "#152435",

		"& span": {
			fontWeight: "600",
		}
	},
	orderCardHead: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 12
	},
	orderCardBody: {
		marginBottom: 16
	},
	orderCardFooter: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		marginTop: "auto",

		"& > *": {
			marginTop: "4px"
		}
	},
	orderCardName: {
		marginBottom: "auto",
		fontSize: "14px",
		lineHeight: "160%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	orderCardButton: {
		"& .MuiButton-root": {
			fontSize: 14,
		}
	},

	orderCardEstimateTitle: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 6
	},
	orderCardEstimate: {
		fontSize: 14,
		lineHeight: "18px",

		marginBottom: 20
	},

	filesTitle: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 6
	},
	files: {
		"& > * > *": {
			width: 60,
			height: 60,
			borderRadius: 4,
			overflow: "hidden",
			border: "1px solid red",
			objectFit: "contain"
		}
	},

	orderPriceBig: {
		fontSize: 20,
		lineHeight: "20px",
		color: "rgba(0,0,0,0.7)",

		"& span": {
			color: "black"
		}
	},
	orderPriceSmall: {
		fontSize: 16,
		lineHeight: "18px",
		color: "rgba(0,0,0,0.7)",

		"& span": {
			color: "black"
		}
	},
	warning: {
		color: 'red',
		marginTop: '5px',
		fontSize: '14px'
	}
}));

export default React.memo(WorkList)
