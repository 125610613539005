import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions, TextField,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import moment from "moment";
import {IMaskInput} from "react-imask";

const TransportInfo = (props) => {
	const {
		application,
		isFullDisabled,
		onChangeMileage
	} = props;
	const classes = useStyles();

	const [mileage, setMileage] = React.useState('');
	const initMileage = () => {
		const mileages = application?.mileages || [];
		const lastMileages = mileages[mileages.length - 1]?.value || '';
		setMileage(lastMileages);
	};
	React.useEffect(() => {
		initMileage();
	}, [application]);

	const [isOpenHistory, setOpenHistory] = React.useState(false);
	const handleChangeOpenHistory = (_val) => {
		setOpenHistory(_val || false);
	}

	const [isOpenChange, setOpenChange] = React.useState(false);
	const handleChangeOpenChange = (_val) => {
		setOpenChange(_val || false);
	}
	const handleApplyNewMileage = async ({ value }) => {
		await onChangeMileage(value);
		setOpenChange(false);
	}

	return (
		<Box>

			<Typography variant="h3" textAlign="left">Информация об автомобиле</Typography>
			<Box mt={2}/>

			<Box className={classes.sectionInfo}>
				<Typography className={classes.sectionInfoLabel}>VIN</Typography>
				<Typography className={classes.sectionInfoValue}>{application?.vin || 'Неизвестно'}</Typography>
			</Box>

			<Box mt={2}/>

			<Box className={classes.sectionInfo}>
				<Typography className={classes.sectionInfoLabel}>Текущий пробег</Typography>
				<Typography className={classes.sectionInfoValue}>{mileage || 'Неизвестно'}</Typography>
				<Box className={classes.sectionInfoActions}>
					<Button disabled={isFullDisabled} variant="outlined" onClick={handleChangeOpenChange.bind(this, true)}>Изменить</Button>
					<Button variant="outlined" onClick={handleChangeOpenHistory.bind(this, true)}>История</Button>
				</Box>
			</Box>


			<VisibleContent visible={Boolean(isOpenHistory)}>
				<DialogHistory
					history={[...(application?.mileages || [])].reverse()}
					onClose={handleChangeOpenHistory.bind(this, false)}
				/>
			</VisibleContent>
			<VisibleContent visible={Boolean(isOpenChange)}>
				<DialogChange
					current={mileage}
					onChange={handleApplyNewMileage}
					onClose={handleChangeOpenChange.bind(this, false)}
				/>
			</VisibleContent>

		</Box>
	)
}
const DialogHistory = (props) => {
	const {
		history,
		onClose
	} = props;
	const classes = useStyles();

	const _getUserName = (item) => {
		return [item?.user?.last_name, item?.user?.first_name].filter((t) => !!t).join(' ') || 'Н/Д'
	}

	return (
		<Dialog
			open={true}
			fullWidth={true}
			maxWidth="sm"
			onClose={onClose}
		>
			<DialogTitle>
				<Typography variant="h3" textAlign="left">
					История изменения пробега ТС
				</Typography>
			</DialogTitle>
			<DialogContent>
				<VisibleContent visible={Boolean(history.length <= 0)}>
					<Typography className={classes.infoMessage}>
						Нет истории изменения
					</Typography>
				</VisibleContent>
				<VisibleContent visible={Boolean(history.length > 0)}>
					<Grid container spacing={1}>
						{history.map((item, index) => (
							<Grid item xs={12} key={`history-${index}`}>
								<Box className={classes.historyCard}>
									<div className={classes.historyCardDate}>
										Дата и время обновления:<br/><span>{moment(item.updated_at).format("DD.MM.YYYY HH:mm:ss")}</span>
									</div>
									<Box mt={0.5}/>
									<div className={classes.historyCardDate}>
										Обновил:<br/><span>{_getUserName(item)}</span>
									</div>
									<Box mt={0.5}/>
									<div className={classes.historyCardDate}>
										Новый пробег ТС:<br/><span>{item.value}</span>
									</div>
								</Box>
							</Grid>
						))}
					</Grid>
				</VisibleContent>
			</DialogContent>
			<DialogActions>
				<Box px={2} pb={1}>
					<Grid container justifyContent="flex-end">
						<Button variant="outlined" size="small" onClick={onClose}>
							Закрыть
						</Button>
					</Grid>
				</Box>
			</DialogActions>
		</Dialog>
	)
};
const DialogChange = (props) => {
	const {
		current,
		onClose,
		onChange
	} = props;
	const classes = useStyles();

	const [value, setValue] = React.useState('');
	const handleChangeValue = ({ target }) => {
		setValue(target?.value || '');
	};
	const handleSubmitValue = () => {
		onChange({
			value
		})
	}

	return (
		<Dialog
			open={true}
			fullWidth={true}
			maxWidth="sm"
			onClose={onClose}
		>
			<DialogTitle>
				<Typography variant="h3" textAlign="left">
					Изменение пробега ТС
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box className={classes.historyCardDate} mb={2}>
					Текущий пробег ТС: <span>{current || '-'}</span>
				</Box>
				<Box>
					<Typography variant="h6" mb={0.5}>Новый пробег ТС</Typography>
					<TextField
						value={String(value)}

						name="phone"
						variant="outlined"
						color="secondary"
						size="small"
						fullWidth={true}

						inputProps={{ mask: Number }}
						InputProps={{ inputComponent: IMaskInput }}

						onChange={handleChangeValue}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Box px={2} pb={1}>
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button variant="outlined" size="small" onClick={onClose}>
								Закрыть
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" size="small" onClick={handleSubmitValue}>
								Применить
							</Button>
						</Grid>
					</Grid>
				</Box>
			</DialogActions>
		</Dialog>
	)
};

const VisibleContent = ({visible, children}) => {
	if (!visible) {
		return null
	}
	return children
}

const useStyles = makeStyles(() => ({
	sectionInfo: {
		width: "auto",
		maxWidth: 420
	},
	sectionInfoLabel: {
		marginBottom: "10px",

		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#647083",
	},
	sectionInfoValue: {
		background: "#FFFFFF",
		border: "1px solid rgba(21, 36, 53, 0.1)",
		borderRadius: "8px",
		padding: "12px 14px",
		boxSizing: "border-box",

		fontWeight: "600",
		fontSize: "15px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	sectionInfoActions: {
		display: "flex",
		flexWrap: "wrap",
		paddingTop: 6,
		marginTop: -4,
		marginLeft: -4,

		"& > *": {
			marginTop: 4,
			marginLeft: 4,
			height: "auto",
			padding: "4px 14px",
			fontSize: "14px",
			lineHeight: "16px",
		}
	},

	historyCard: {
		padding: "12px",
		boxSizing: "border-box",
		background: "#F4F6F8",
		borderRadius: "4px",
	},
	historyCardDate: {
		fontSize: "14px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#647083",

		"& span": {
			fontWeight: "600",
		}
	},

	infoMessage: {
		fontSize: "16px",
		lineHeight: "20px",
		color: "#8698B1"
	}
}));

export default TransportInfo
