import React from "react";
import {
	Box,
	Grid,
	Button,
	Popover,
	MenuList,
	MenuItem,
	ListItemText,
	IconButton,

	useMediaQuery,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Menu as MenuIcon,
	Search as SearchIcon,
	ArrowBack as ArrowBackIcon,
	PersonOutlineOutlined as PersonIcon
} from "@mui/icons-material";
import PopupState, {
	bindTrigger,
	bindPopover
} from 'material-ui-popup-state';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {palette} from "../../theme/common";
import {logoutUser, toggleSearchPanel} from "../../states/global";
import Notification from "../_Client/components/Notification";

const ClientLayouts = ({ children }) => {
	const classes = useStyles();
	const routeMatch = useRouteMatch();
	const user = useSelector((state) => state?.global?.user);
	const isAuth = Boolean(Object.keys(user || {}).length > 0);

	return (
		<div
			id="scoll-body-bar"
			className={classes.root}
		>

			<HeaderElement/>

			<div className={classes.body}>
				{ children }
			</div>

		</div>
	)
};
const HeaderElement = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeMatch = useRouteMatch();
	const user = useSelector((state) => state?.global?.user);
	const isAuth = Boolean(Object.keys(user || {}).length > 0);
	const isShowBackButton = ['/applications/:id'].includes(routeMatch.path);
	const isMobile = useMediaQuery('(max-width:859px)');
	const isShowButtonSearch = Boolean(useMediaQuery('(max-width:768px)') && routeMatch.path === '/applications');

	const handleGoBack = () => {
		history.goBack();
	};
	const handleExitUser = () => {
		dispatch(logoutUser());
	};
	const handleOpenSearch = () => {
		dispatch(toggleSearchPanel());
	}

	const _userRole = () => {
		if (user?.role === 'mechanic') {
			return "Механик"
		}
		if (user?.role === 'consultant') {
			return "Консультант"
		}
		if (user?.role === 'admin') {
			return "Администратор"
		}
		return ""
	}

	return (
		<div className={classes.header}>
			<VisibleContent visible={Boolean(isShowBackButton)}>
				<IconButton color="primary" onClick={handleGoBack} className={classes.headerBack}>
					<VisibleContent visible={isMobile}>
						<img src={require('../../assets/svg/header/arrow-left.svg').default}/>
					</VisibleContent>
					<VisibleContent visible={!isMobile}>
						<ArrowBackIcon/>
					</VisibleContent>
				</IconButton>
				<Box mr={1}/>
			</VisibleContent>
			<Link className={classes.headerLogo} to="/">
				<img src={require('../../assets/logos/logo-full.png').default}/>
			</Link>
			<VisibleContent visible={isShowButtonSearch}>
				<Box ml={1}/>
				<Button
					className={classes.profileMobileButton}
					onClick={handleOpenSearch}
				><img src={require('../../assets/svg/header/search.svg').default}/></Button>
			</VisibleContent>

			<Box mx="auto"/>

			<VisibleContent visible={!isMobile}>
				<VisibleContent visible={['admin', 'consultant'].includes(user?.role)}>
					<Grid container spacing={1} mr={3} sx={{ flex: 0 }} wrap="nowrap">
						<VisibleContent visible={['admin'].includes(user?.role)}>
							<Grid item>
								<Link to="/users" className={classes.headerMenuItem}>Пользователи</Link>
							</Grid>
						</VisibleContent>
						<Grid item>
							<Link to="/dictionary" className={classes.headerMenuItem}>Справочник</Link>
						</Grid>
					</Grid>
				</VisibleContent>
				<VisibleContent visible={isAuth}>
					<Notification/>
					<div className={classes.headerProfile}>
						<div className="--title">
							{[user?.lastName, user?.firstName].filter((t) => !!t).join(' ')}
						</div>
						<div className="--subtitle">
							{_userRole()}
						</div>
					</div>
					<Button
						size="small"
						variant="contained"
						onClick={handleExitUser}
					>Выход</Button>
				</VisibleContent>
			</VisibleContent>
			<VisibleContent visible={isMobile && isAuth}>
				<VisibleContent visible={Boolean(user?.role === 'admin')}>
					<ProfileMenu/>
					<Box mr={1}/>
				</VisibleContent>
				<Notification/>
				<ProfileMobile
					name={[user?.lastName, user?.firstName].filter((t) => !!t).join(' ')}
					role={_userRole()}
					onLogout={handleExitUser}
				/>
			</VisibleContent>
		</div>
	)
};
const ProfileMenu = ({ name, role, onLogout }) => {
	const classes = useStyles();
	const history = useHistory();

	const handleRoute = (path, state) => {
		history.push(path);
		state.close()
	}

	return (
		<PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<div>
					<Button
						className={classes.profileMobileButton}
						{...bindTrigger(popupState)}
					>
						<MenuIcon color="primary"/>
					</Button>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuList className={classes.profileMenuMenu}>
							<MenuItem onClick={handleRoute.bind(this, '/users', popupState)}>
								<ListItemText>Пользователи</ListItemText>
							</MenuItem>
						</MenuList>
					</Popover>
				</div>
			)}
		</PopupState>
	)
};
const ProfileMobile = ({ name, role, onLogout }) => {
	const classes = useStyles();
	return (
		<PopupState variant="popover" popupId="demo-popup-popover">
			{(popupState) => (
				<div>
					<Button
						className={classes.profileMobileButton}
						{...bindTrigger(popupState)}
					>
						<img src={require('../../assets/svg/header/profile.svg').default}/>
					</Button>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Box className={classes.profileMobileContent}>
							<div className={classes.profileMobileTitle}>ФИО</div>
							<div className={classes.profileMobileValue}>{ name }</div>
							<Box mt={1}/>
							<div className={classes.profileMobileTitle}>Роль</div>
							<div className={classes.profileMobileValue}>{ role }</div>
							<Box mt={1}/>
							<Button
								fullWidth
								size="small"
								variant="contained"
								className={classes.profileMobileExit}
								onClick={onLogout}
							>Выход</Button>
						</Box>
					</Popover>
				</div>
			)}
		</PopupState>
	)
};
const VisibleContent = ({ visible, children }) => {
	if (!visible) return null
	return children
};

const useStyles = makeStyles(() => ({
	root: {
		background: "#F2F2F6",
		minHeight: "100vh"
	},

	header: {
		display: "flex",
		alignItems: "center",
		position: "fixed",
		zIndex: 600,
		top: 0, left: 0, right: 0,
		background: "white",
		padding: "12px 12px",
		boxSizing: "border-box",
		boxShadow: "0px 4px 4px 0px #0000001A",
		"@media(max-width: 859px)": {
			height: 52,
			padding: "0px 8px",
		}
},
	headerLogo: {
		display: "flex",
		alignItems: "center",

		"& img": {
			height: 28,
		},
		"@media(max-width: 859px)": {
			"& img": {
				height: 16,
			}
		}
	},
	headerBack: {
		maxHeight: 28,
		padding: 0,
		"& img": {
			width: "100%",
			height: "100%",
		},
		"@media(max-width: 859px)": {
			padding: "0",
			boxSizing: "border-box",
			width: "30px",
			height: "30px"
		}
	},
	headerProfile: {
		display: "flex",
		flexDirection: "column",
		margin: "0 8px",
		"& .--title": {
			fontSize: 16,
			lineHeight: "18px",
			maxWidth: 120,
			overflow: "hidden",
			display: "-webkit-box",
			"-webkit-line-clamp": 1,
			"-webkit-box-orient": "vertical",
		},
		"& .--subtitle": {
			fontSize: 14,
			lineHeight: "16px",
			opacity: 0.6
		},
	},
	headerMenuItem: {
		padding: "6px 16px",
		boxSizing: "border-box",
		background: palette.primary.main,
		borderRadius: 4,

		fontSize: 16,
		lineHeight: "16px",
		color: palette.primary.contrastText,
	},

	body: {
		padding: "0 12px",
		paddingTop: "82px",
		paddingBottom: "24px",
		"@media(max-width: 859px)": {
			padding: "0 8px",
			paddingTop: "64px",
		},
	},

	profileMobileButton: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 4,
		boxSizing: "border-box",
		width: 34,
		height: 34,
		minWidth: 0,
		border: "1px solid",
		borderColor: palette.primary.main,
		borderRadius: "100%",
		"& svg": {
			width: "auto",
			height: "auto",
		}
	},
	profileMobileContent: {
		padding: 8,
		boxSizing: "border-box"
	},
	profileMobileTitle: {
		fontSize: 12,
		lineHeight: "12px",
		opacity: 0.6
	},
	profileMobileValue: {
		fontSize: 15,
		lineHeight: "15px",
	},
	profileMobileExit: {},

	profileMenuMenu: {
		"& .MuiMenuItem-root": {
			padding: "4px 16px",
			height: "auto",
			minHeight: "initial",
		},
		"& .MuiTypography-root": {
			fontSize: 16,
			lineHeight: "20px",
		},
	}
}));

export default ClientLayouts
