import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Article as ArticleIcon
} from "@mui/icons-material";
import {convertNewlinesToBreaks} from "../../../../../helper/convertor";
import {VideoPreview} from "../../../../../components";
import {useSelector} from "react-redux";

const RecommendationList = (props) => {
	const {
		data,
		hideButtonCreate,
		applicationStatus,
		disabledEdit
	} = props;
	const classes = useStyles();

	return (
		<>

			<Grid container justifyContent="space-between">
				<Grid item>
					<Typography variant="h3" textAlign="left">Рекомендации</Typography>
				</Grid>
				<VisibleContent visible={!Boolean(hideButtonCreate)}>
					<Grid item>
						<Button
							size="small"
							variant="contained"
							disabled={disabledEdit}
							onClick={props.onOpenCreateRecommendation.bind(this, null)}
						>
							Добавить рекомендацию
						</Button>
					</Grid>
				</VisibleContent>
			</Grid>

			<Box mb={2}/>

			<VisibleContent visible={Boolean((data || []).length <= 0)}>
				<Typography mb={1} className={classes.helpMessage}>Нет рекомендаций</Typography>
			</VisibleContent>
			<VisibleContent visible={Boolean((data || []).length > 0)}>
				<Grid container spacing={1}>
					{(data || []).map((recommendation) => (
						<Grid item xs={12} md={6}>
							<RecommendationCard
								key={`RecommendationCard-${recommendation.id}`}
								item={recommendation}
								disabledEdit={disabledEdit}
								applicationStatus={applicationStatus}

								onEdit={props.onOpenEdit}
								onDelete={props.onDeleteRecommendation}
								onImagesVideos={props.onOpenDialogImagesMessage}
							/>
						</Grid>
					))}
				</Grid>
			</VisibleContent>

		</>
	)
};
const RecommendationCard = (props) => {
	const {
		item,
		disabledEdit,
		applicationStatus,

		onEdit,
		onDelete,
		onImagesVideos
	} = props;
	const { user } = useSelector(state => state.global);
	const classes = useStyles();
	const isConsultantAdmin = Boolean(user?.role === 'consultant' || user?.role === 'admin');
	const isAvaibleEdit = Boolean(applicationStatus === "EXECUTED" && isConsultantAdmin);
	const isDisabledEdit = Boolean(disabledEdit);

	const [isShowClientRecommendation, setShowClientRecommendation] = React.useState(false);
	const handleChangeShowClientRecommendation = () => {
		setShowClientRecommendation(!isShowClientRecommendation);
	}

	const _mechanic = () => {
		return [
			item?.mechanic?.lastName,
			item?.mechanic?.firstName,
			item?.mechanic?.patronymic,
		].filter((t) => !!t).join(' ') || "Н/Д"
	};
	const _cost = () => {
		if (!item.confirmedAt && !item.cost) {
			return 'Стоимость отсутствует, согласование невозможно'
		}
		if (item?.cost) {
			return `${item?.cost} ₽`
		}
		return 'Н/Д'
	};

	const _isShowActions = () => {
		if (isDisabledEdit) {
			return false
		}
		if (user.role === 'admin') {
			return true
		}
		if (String(user.id) !== String(item.mechanicId)) {
			return false
		}
		return true
	}

	const handleOpenPopUpImages = (index) => {
		const pictures = (item.pictures || [])
			.filter((t) => {
				return !Boolean(['application/pdf'].includes(t.mimetype))
			});
		onImagesVideos({
			index,
			files: pictures,
			message: item?.value || ""
		});
	}

	return (
		<Box className={classes.recommendationCard}>
			<div className={classes.recommendationCardHead}>
				Рекомендация от механика: <span>{_mechanic()}</span>
			</div>
			<div className="--divider"></div>
			<div className={classes.recommendationCardBody}>
				<div dangerouslySetInnerHTML={{
					__html: convertNewlinesToBreaks(item?.value || 'Н/Д')
				}}/>
			</div>
			<div className="--divider"></div>
			<VisibleContent visible={isShowClientRecommendation}>
				<div className={classes.recommendationCardBody}>
					<div>Рекомендация для клиента</div>
					<div dangerouslySetInnerHTML={{
						__html: convertNewlinesToBreaks(item?.admin_note || 'Н/Д')
					}}/>
				</div>
				<div className="--divider"></div>
			</VisibleContent>
			<VisibleContent visible={Boolean((item.pictures || []).length)}>
				<div className={classes.recommendationCardImages}>
					{(item.pictures || []).map((file, index) => {
						const isPdf = Boolean((file?.file?.type || file?.mimetype || "").includes("pdf"));
						if (isPdf) {
							return (
								<Box
									sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
									component="a"
									href={file.fileUrl}
									target="_blank"
								>
									<ArticleIcon sx={{ color: "#FF3F3F" }}/>
								</Box>
							)
						}

						const isVideo = Boolean((file?.file?.type || file?.mimetype || "").includes("video"));
						if (!!isVideo) {
							return (
								<div>
									<VideoPreview
										file={file}
										onClick={handleOpenPopUpImages.bind(this, index)}
									/>
								</div>
							)
						}
						return (
							<div>
								<img
									src={file.fileUrl}
									onClick={handleOpenPopUpImages.bind(this, index)}
								/>
							</div>
						)
					})}
				</div>
			</VisibleContent>
			<VisibleContent visible={_isShowActions() || isConsultantAdmin}>
				<div className="--divider"></div>
				<div className={classes.recommendationCardControls}>
					<Grid container spacing={1}>
						<VisibleContent visible={isConsultantAdmin}>
							<Grid item xs={12}>
								<Button variant="outlined" fullWidth onClick={handleChangeShowClientRecommendation}>
									{isShowClientRecommendation ? 'Свер. рекомендацию для клиента' : 'Разв. рекомендацию для клиента'}
								</Button>
							</Grid>
						</VisibleContent>
						<Grid item xs={12}>
							<Button disabled={disabledEdit && !isAvaibleEdit} variant="outlined" fullWidth onClick={onEdit.bind(this, item, false)}>
								Редактировать
							</Button>
						</Grid>
						<VisibleContent visible={_isShowActions()}>
							<Grid item xs={12}>
								<Button disabled={disabledEdit} variant="outlined" fullWidth onClick={onDelete.bind(this, item.id, false)}>
									Удалить
								</Button>
							</Grid>
						</VisibleContent>
					</Grid>
				</div>

			</VisibleContent>
		</Box>
	)
}
const VisibleContent = ({visible, children}) => {
	if (!visible) return null
	return children || null
}

const useStyles = makeStyles(() => ({
	helpMessage: {
		fontWeight: "600",
		fontSize: "18px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},

	recommendationCard: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		background: "#F4F6F8",
		borderRadius: "16px",
		padding: "20px",
		boxSizing: "border-box",

		"& .--divider": {
			height: 1,
			margin: "12px -20px",
			background: "#152435",
			opacity: 0.1
		},
		"@media(max-width: 1199px)": {
			background: "white"
		}
	},
	recommendationCardHead: {
		fontSize: "15px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		color: "#152435",

		"& span": {
			fontWeight: "600",
		}
	},
	recommendationCardBody: {
		marginBottom: "auto",
		fontSize: "14px",
		lineHeight: "160%",
		letterSpacing: "-0.02em",
		color: "#152435",
	},
	recommendationCardInfo: {
		display: "flex",
		flexDirection: "column",

		"& > *": {
			marginTop: 5,
			"&:first-child": {
				marginTop: 0,
			}
		},
		"& .--label": {
			marginBottom: 4,
			fontSize: "12px",
			lineHeight: "100%",
			letterSpacing: "-0.02em",
			color: "#647083",
		},
		"& .--value": {
			fontSize: "14px",
			lineHeight: "150%",
			letterSpacing: "-0.02em",
			color: "#152435",
		},
	},
	recommendationCardImages: {
		display: "flex",
		flexWrap: "wrap",
		marginTop: -4,
		marginLeft: -4,

		"& > *": {
			width: 64,
			height: 64,
			borderRadius: 4,
			marginTop: 4,
			marginLeft: 4,
			position: "relative",
			overflow: "hidden",
			cursor: "pointer",

			"& img, & video": {
				width: "100%",
				height: "100%",
				objectFit: "cover",
				objectPosition: "center"
			}
		}
	},
	recommendationCardControls: {
		"& .MuiButton-root": {
			fontSize: 14,
		}
	}
}));

export default RecommendationList
