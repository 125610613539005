import React from "react";
import {Box, Grid, TextField, Typography, FormLabel} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import {IMaskInput} from "react-imask";
import {convertNewlinesToBreaks} from "../../../../../helper/convertor";


let timeOutChange = null;

const UserInfo = (props) => {
	const {
		vin,
		info,
		client,
		mileage,
		reasonForContacting,

		onChangeMileage
	} = props;
	const classes = useStyles();
	const {user} = useSelector(state => state.global);

	const [localMileage, setLocalMileage] = React.useState(String(mileage || ''));
	React.useEffect(() => {
		setLocalMileage(String(mileage || ''));
	}, [mileage]);

	const getClientName = () => {
		if (client.isLegal) {
			return (
				<>
					<Grid item>
						Компания - {client?.companyName || 'Нет данных о компании'}
					</Grid>
					{user?.role !== 'mechanic' && (
						<Grid item>
							Номер телефона - <b>{client?.phone || "Нет данных о номере"}</b>;
						</Grid>
					)}
				</>
			);
		}

		const fullName = [
			client?.lastName || '',
			client?.firstName || '',
			client?.patronymic || '',
		].filter((t) => !!t).join(' ');

		return (
			<>
				<Grid item>
					ФИО - <b>{fullName}</b>;
				</Grid>
				<Grid item>
					Номер телефона - <b>{client?.phone || "Нет данных о номере"}</b>;
				</Grid>
			</>
		);
	};

	return (
		<>
			<Box mb={1}>
				<Typography variant="h3" textAlign="left">Информация о клиенте</Typography>
			</Box>
			<Grid className={classes.root} container spacing={1}>
				{getClientName()}
        <Grid item xs={12}>
          Причина обращения - <div dangerouslySetInnerHTML={{__html: convertNewlinesToBreaks(String(reasonForContacting || 'Неизвестно'))}}/>
        </Grid>
			</Grid>
		</>
	);
};


const useStyles = makeStyles(() => ({
	root: {
		"& b": {
			fontWeight: "500",
			textDecoration: "underline"
		}
	}
}));

export default React.memo(UserInfo)
