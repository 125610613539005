import React from "react";
import {
	Box,
	Grid,
	Button,
	Backdrop,
	TextField,
	IconButton,
	Typography,
	CircularProgress,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	makeStyles,
	withStyles
} from "@mui/styles";
import {
	Delete as DeleteIcon
} from "@mui/icons-material";
import {
	palette
} from "../../../../../theme/common";
import {
	DialogConfirmation, FileUploader
} from "../../../../../components";
import {getTricepsWorkCodes} from "../../../../../utils/standardTricepsWork";
import optimizationImageVideo from "../../../../../common/OptimizationImageVideo";
import agent from "../../../../../agent/agent";

const initForm = {
	items: [],
	files: [],
};

class DialogCreateWork extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: JSON.parse(JSON.stringify({...initForm})),

			stepForm: "works",

			isOpen: false,
			isEdit: false,
			isBackdrop: false,
			isRecommendation: false,

			onSubmit: null,
			onDeleteWorkFile: null
		};
	}

	open = ({onSubmit, initForm: editInitForm, onDeleteWorkFile, isRecommendation}) => {
		const isSaveCrateWork = Boolean(localStorage.getItem('create-work-save-form'));

		const applicationDescriptionArray = String(editInitForm?.applicationDescription || editInitForm?.value || '').split('<br>');
		if (editInitForm && applicationDescriptionArray.length !== (editInitForm?.items || []).length) {
			editInitForm.items = applicationDescriptionArray.map((item) => {
				return {
					description: item
				}
			})
		};
		if (editInitForm && (editInitForm?.items || []).length > 0) {
			const allFiles = editInitForm?.files || [];
			editInitForm.items = [...(editInitForm?.items || [])].map((_item) => {
				return {
					..._item,
					images: allFiles.filter((allF) => (_item?.images || []).find((t) => allF.fileId === t.id))
				}
			})
		}

		this.setState({
			form: editInitForm || JSON.parse(JSON.stringify({...initForm})),

			isOpen: true,
			isEdit: Boolean(editInitForm),
			isSaveCrateWork: isSaveCrateWork,

			onSubmit,
			onDeleteWorkFile,


			isRecommendation
		})
	}
	close = () => {
		this.setState({
			form: JSON.parse(JSON.stringify({...initForm})),

			stepForm: "works",

			isOpen: false,
			isEdit: false,
			isBackdrop: false,
			isRecommendation: false,

			onSubmit: null,
			onDeleteWorkFile: null
		})
	}

	changeForm = (form) => {
		this.setState({form});
	}
	submitForm = async () => {
		const {form} = this.state;
		const body = {
			...form,
			applicationDescription: (form?.items || []).map((t) => t.description).join('<br>')
		};
		this.state.onSubmit(body, true);
	}
	changeStepForm = async (direction) => {
		const {stepForm} = this.state;
		if (stepForm === "files" && direction === "next" || true) {
			await this.submitForm();
			return
		}
		if (stepForm === "files" && direction === "prev") {
			this.setState({stepForm: "works"})
			return
		}
		if (stepForm === "works" && direction === "next") {
			this.setState({stepForm: "files"})
			return
		}
	}

	removeImage = (fileId, callback) => {
		const serviceId = this.state?.form?.id || 0;
		this.state.onDeleteWorkFile(fileId, serviceId, callback);
	}


	_title = () => {
		const { isEdit, isRecommendation } = this.state;
		const _type = Boolean(isRecommendation) ? 'рекомендации' : 'работы';

		if (isEdit) {
			return `Редактирование ${ _type }`
		}
		return `Добавление ${ _type }`
	}
	_formTitle = () => {
		const { isEdit, isRecommendation } = this.state;
		const _type = Boolean(isRecommendation) ? 'рекомендаций' : 'работ';

		return `Перечень ${ _type }`
	}

	render() {
		const {
			form,
			isOpen,
			isEdit,
			stepForm,
			isBackdrop
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<>

				<Dialog
					open={isOpen}
					fullWidth
					size="lg"
					className={classes.dialog}
				>
					<DialogTitle className={classes.dialogTitle}>
						<Typography variant="h3">
							{this._title()}
						</Typography>
					</DialogTitle>

					<DialogContent>
						<Box mt={2}/>
						<WorkList
							form={form}
							title={this._formTitle()}
							onChange={this.changeForm}
							onDeleteImage={this.removeImage}
						/>
					</DialogContent>

					<DialogActions>
						<Box px={2} pb={1} sx={{flex: 1}}>
							<Grid container spacing={2} justifyContent="space-between">
								<Grid item>
									<Button
										size="small"
										variant="outlined"
										className={classes.dialogActionsButton}
										onClick={this.close}
									>
										Закрыть
									</Button>
								</Grid>
								<Grid item>
									<Button
										size="small"
										variant="contained"
										className={classes.dialogActionsButton}
										onClick={this.changeStepForm.bind(null, 'next')}
									>Сохранить</Button>
								</Grid>
							</Grid>
						</Box>
					</DialogActions>
				</Dialog>

				<Backdrop open={isBackdrop}>
					<CircularProgress/>
				</Backdrop>

			</>
		)
	}
};
const WorkList = (props) => {
	const {
		form,
		title,
		onChange,
		onDeleteImage
	} = props;
	const classes = useStyles();
	const refDialogConfirmation = React.createRef();

	const handleAddWork = () => {
		let _form = {...form};
		_form.items.push({description: '', images: []});
		onChange(_form);
	}
	const handleEditWork = (index, name, {target}) => {
		let _form = {...form};
		_form.items[index][name] = target.value;
		onChange(_form);
	}
	const handleRemoveWork = (index, isConfirm) => {
		if (!isConfirm) {
			refDialogConfirmation.current.onOpen({
				message: 'Вы точно хотите удалить работу?',
				acceptButtonAction: handleRemoveWork.bind(this, index, true)
			})
			return
		}

		let _form = {...form};
		_form.items.splice(index, 1);
		onChange(_form);
	}

	return (
		<>
			<Typography className={classes.graySectionTitle}>{title}</Typography>
			<Grid container spacing={1}>
				{(form?.items || []).map((item, index) => (
					<Grid item xs={12}>
						<Box className={classes.graySection}>
							<ElementWorkList
								item={item}
								files={form.files}
								onEdit={handleEditWork.bind(this, index, 'description')}
								onEditImages={handleEditWork.bind(this, index, 'images')}
								onRemove={handleRemoveWork.bind(this, index, false)}
								onDeleteImage={onDeleteImage}
							/>
						</Box>
					</Grid>
				))}
			</Grid>
			<Box mt={3}/>
			<Button
				fullWidth
				size="small"
				variant="outlined"
				onClick={handleAddWork}
			>Новая запись</Button>
			<DialogConfirmation ref={refDialogConfirmation}/>
		</>
	)
};
const ElementWorkList = (props) => {
	const {
		item,
		onEdit,
		onRemove,
		onEditImages,
		onDeleteImage
	} = props;
	const classes = useStyles();

	return (
		<Box className={classes.elementWorkList}>
			<TextField
				value={item.description}
				multiline
				fullWidth
				minRows={3}
				maxRows={6}
				size="small"
				variant="outlined"
				placeholder="Введите..."
				className={classes.graySectionInput}
				onChange={onEdit}
			/>
			<IconButton
				className={classes.graySectionRemove}
				onClick={onRemove}
			><DeleteIcon/></IconButton>

			<PhotoImageMini
				images={item.images || []}
				onChange={onEditImages}
				onDelete={onDeleteImage}
			/>
		</Box>
	)
};
const PhotoImageMini = (props) => {
	const {
		images,
		onChange,
		onDelete,
		disabledCreate
	} = props;
	const classes = useStyles();

	const handleChangeForm = (event) => {
		onChange(event);
	}

	return (
		<FileUploader
			value={images}
			multiple={true}
			name="files"
			disabledCreate={disabledCreate}
			accept={{
				"image/gif": [".gif"],
				"image/jpeg": [".jpeg", ".jpg"],
				"image/png": [".png"],
				"image/webp": [".webp"],

				"video/mpeg": [".mpeg"],
				"video/mp4": [".mp4", ".mkv"],
				"video/mkv": [".mp4", ".mkv"],
				"video/ogg": [".ogv"],
				"video/webm": [".webm"],
				"video/x-msvideo": [".avi"],
				"video/3gpp": [".3gp"],
				"video/3gpp2": [".3g2"],
				"video/quicktime": [".quicktime"],

				'application/pdf': ['.pdf'],
				'application/msword': ['.doc'],
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
			}}
			onChange={handleChangeForm}
			onDeleteWorkFile={onDelete}
		/>
	)
}
const PhotoImage = (props) => {
	const {
		form,
		onChange,
		onDelete
	} = props;
	const classes = useStyles();

	const handleChangeForm = ({target}) => {
		const {name, value} = target;
		let _form = {...form};
		_form[name] = value;
		onChange(_form);
	}

	return (
		<Box className={classes.graySection}>
			<Typography className={classes.graySectionTitle}>Фото / видео</Typography>
			<FileUploader
				value={form.files || []}
				multiple={true}
				name="files"
				accept={{
					"image/gif": [".gif"],
					"image/jpeg": [".jpeg", ".jpg"],
					"image/png": [".png"],
					"image/webp": [".webp"],

					"video/mpeg": [".mpeg"],
					"video/mp4": [".mp4"],
					"video/ogg": [".ogv"],
					"video/webm": [".webm"],
					"video/x-msvideo": [".avi"],
					"video/3gpp": [".3gp"],
					"video/3gpp2": [".3g2"],
					"video/quicktime": [".quicktime"],
				}}
				onChange={handleChangeForm}
				onDeleteWorkFile={onDelete}
			/>
		</Box>
	)
}
const VisibleContent = ({visible, children}) => {
	if (!visible) return null
	return children
}

const useStyles = makeStyles((theme) => ({
	graySection: {
		background: "#F4F6F8",
		padding: "12px 20px",
		boxSizing: "border-box",
		borderRadius: 10,

		"@media(max-width: 1023px)": {
			padding: "8px 12px",
			borderRadius: 6,
		},
	},
	graySectionTitle: {
		marginBottom: 16,

		fontSize: 22,
		lineHeight: "24px",
		color: "#152435",
		fontWeight: "600",

		"@media(max-width: 1023px)": {
			marginBottom: 8,
			fontSize: 16,
			lineHeight: "18px",
		},
	},
	graySectionInput: {
		"& .MuiOutlinedInput-root": {
			background: "white",
			paddingRight: 48,
		}
	},
	graySectionRemove: {
		position: "absolute",
		top: "0",
		right: "0",
		background: palette.primary.main,

		borderRadius: "0",
		padding: "4px",

		"& svg": {
			color: "white"
		}
	},

	elementWorkList: {
		position: "relative",
		overflow: "hidden",
		borderRadius: 4,

		"& .root-dropzone": {
			border: "1px solid #c4c4c4",
			background: "white",
			marginTop: "8px",
			height: 32,
		},
		"& .root-dropzone div": {
			fontSize: 12,
		},
	}
}))

const styles = {
	dialog: {},
	dialogTitle: {
		background: palette.primary.main,
		"& .MuiTypography-root": {
			textAlign: 'left',
			color: palette.primary.contrastText,
		}
	},
	dialogActionsButton: {},
};
DialogCreateWork = withStyles(styles)(DialogCreateWork);

export default DialogCreateWork
export {
	PhotoImage,
	PhotoImageMini
}
