// @flow
import {compose} from 'recompose';
import {connect} from 'react-redux';

import Application from './Application';

export default compose(
	connect(
		state => ({
			user: state?.global?.user,
			userRole: state?.global?.user?.role || ""
		}),
		dispatch => ({}),
	),
)(Application);
