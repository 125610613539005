import React from "react";
import {
	Button,

	Dialog
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import Quagga from "@ericblade/quagga2";

class JsBarCodeScanner extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {}

		this.refRoot = React.createRef();
	}

	componentDidMount = async () => {
		await this.initScanner();
	}
	componentWillUnmount = () => {
		Quagga.stop();
	}

	initScanner = async () => {
		if (!this.refRoot.current) {
			setTimeout(async () => {
				await this.initScanner();
			}, 1000);

			return
		}

		const {
			width,
			height
		} = this.refRoot.current.getBoundingClientRect();

		let devices = await navigator.mediaDevices.enumerateDevices();
		devices = devices.filter((device) => {
			if (!device.getCapabilities) {
				return false
			}
			const capabilities = device.getCapabilities();
			return Boolean((capabilities.facingMode || []).includes('environment'))
		});
		devices = devices.sort((a, b) => {
			const aCapabilities = a.getCapabilities();
			const bCapabilities = b.getCapabilities();

			if (aCapabilities?.frameRate?.max > bCapabilities?.frameRate?.max) {
				return 0
			}
			if (aCapabilities?.frameRate?.max < bCapabilities?.frameRate?.max) {
				return -1
			}
			return 0
		})

		const self = this;

		Quagga.init(
			{
				frequency: 100,
				inputStream: {
					type: "LiveStream",
					target: document.querySelector('#JsBarCodeScanner'),
					constraints: {
						width: height,
						height: width,
						facingMode: "environment",
						deviceId: Boolean(devices.length > 0) ? devices?.[0].deviceId : null,
					},
					area: {
						top: "0%",
						right: "0%",
						left: "0%",
						bottom: "0%"
					},
				},
				locator: {
					patchSize: "small",
					halfSample: false
				},
				decoder: {
					readers: [
						"code_128_reader",
						"code_39_reader",
						"code_39_vin_reader",
						"code_93_reader",
						"code_32_reader",
					],
					multiple: false,
					locate: true
				},

				locate: false
			},
			function (err) {
				if (err) {
					return console.error(err);
				}
				Quagga.start();
			}
		);
		Quagga.onDetected(this.onDetected);
		Quagga.offDetected(this.onDetected);
		Quagga.onProcessed(function (result) {
			var drawingCtx = Quagga.canvas.ctx.overlay,
				drawingCanvas = Quagga.canvas.dom.overlay;

			if (result) {
				if (result.boxes) {
					drawingCtx.clearRect(
						0,
						0,
						parseInt(drawingCanvas.getAttribute("width")),
						parseInt(drawingCanvas.getAttribute("height"))
					);
					result.boxes
						.filter(function (box) {
							return box !== result.box;
						})
						.forEach(function (box) {
							Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
								color: "green",
								lineWidth: 2
							});
						});
				}
				if (result.box) {
					Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
						color: "#00F",
						lineWidth: 2
					});
				}
				if (result.codeResult && result.codeResult.code) {
					self.onDetected(result);
				}
			}
		});
	}
	onDetected = (event) => {
		this.props.onResult(event?.codeResult?.code);
	}

	render () {
		const {
			classes,

			onCancel
		} = this.props;

		return (
			<Dialog
				open={true}
				fullScreen
			>
				<div
					ref={this.refRoot}
					id="JsBarCodeScanner"
					className={classes.root}
				/>

				<Button
					className={classes.buttonClose}
					variant="outlined"
					onClick={onCancel}
				>Отменить</Button>

			</Dialog>
		)
	}
}

const styles = {
	root: {
		width: "100%",
		height: "100%",

		"& canvas": {
			position: "absolute",
			left: 0,
			top: 0,
			zIndex: 10,
			pointerEvents: "none"
		}
	},

	buttonClose: {
		position: "absolute",
		bottom: 24,
		left: 12,
		right: 12,
		zIndex: 200,
		backgroundColor: "transparent"
	},
};
JsBarCodeScanner = withStyles(styles)(JsBarCodeScanner);

export default JsBarCodeScanner
