import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {
	VisibleContent
} from "../../../../../components";
import {makeStyles} from "@mui/styles";
import ActionStatus from "../ActionStatus";

const ApplicationInfo = (props) => {
	const {
		application,
		isDiagnostics,
		isFullDisabled,
		onChangeOrderStatus
	} = props;
	const classes = useStyles();

	return (
		<>
			<Box mb={1}>
				<Typography variant="h3" textAlign="left">Информация о ЗН</Typography>
			</Box>
			<Grid className={classes.root} container spacing={1}>
				<Grid item>
					ID - {application.id || 'ID не найден'};
				</Grid>
				<Grid item>
					Трицепс ID - {application.tricepsOrderId || "Трицепс ID не найден"};
				</Grid>
			</Grid>

			<VisibleContent visible={!isDiagnostics}>
				<Box mt={3}/>
				<ActionStatus
					info={application}
					disabled={isFullDisabled}
					onChange={onChangeOrderStatus}
				/>
			</VisibleContent>
		</>
	);
};

const useStyles = makeStyles(() => ({
	root: {
		"& b": {
			fontWeight: "500",
			textDecoration: "underline"
		}
	}
}));

export default React.memo(ApplicationInfo)
